$br: 5px;
$spread: 5px;
$gap: 50px;

$purple: #7c00ff;
$pink: #ff0072;
$red: #ff0000;
$green: #6bff98;
$orange: #ff9600;
$blue: #60cafe;
$black: #000000;
$gray: #cccbcb;

// Reskin
// $black: #000000;
// $yellow: rgb(255, 251, 8);
// $gray: #cccbcb;
// $purple: $yellow;
// $pink: $yellow;
// $red: rgb(18, 19, 18);
// $green: $yellow;
// $orange: black;
// $blue: $yellow;
// * {
//   color: black !important;
//   font-family: serif;
// }

$highlightBlue: lighten($blue, 20);
$shadowBlue: darken($blue, 40);

$highlightOrange: lighten($orange, 20);
$shadowOrange: darken($orange, 25);

$highlight: rgba(white, 0.6);
$shadow: rgba(rgb(20, 18, 20), 0.6);

@font-face {
  font-family: "arial-transformed";
  src: url(/assets/arial_transformed.ttf);
}

@mixin bp($point) {
  @if $point==xl {
    @media (min-width: 95rem) {
      @content;
    }
  } @else if $point==l {
    @media (min-width: 90rem) {
      @content;
    }
  } @else if $point==m {
    @media (min-width: 65rem) {
      @content;
    }
  } @else if $point==s {
    @media (min-width: 35rem) {
      @content;
    }
  }
}

$sans: Helvetica, Arial, sans-serif;
$condensed: "arial-transformed", sans-serif;

:root {
  --ratio: 1.25;
  --ms-0: clamp(1rem, 3vw, 2.4rem);
  --ms--2: calc(var(--ms--1) / var(--ratio));
  --ms--1: calc(var(--ms-0) / var(--ratio));
  --ms-1: calc(var(--ms-0) * var(--ratio));
  --ms-2: calc(var(--ms-1) * var(--ratio));
  --ms-3: calc(var(--ms-2) * var(--ratio));
  --ms-4: calc(var(--ms-3) * var(--ratio));
  --ms-5: calc(var(--ms-4) * var(--ratio));
}
