.ticker {
  font-size: var(--ms-2);
  text-transform: uppercase;
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
  white-space: nowrap;
  padding: 1rem;
  height: 100%;
  position: relative;
}

@keyframes ticker {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.ticker-inner {
  display: inline-block;
  letter-spacing: 0.03em;
  transform-origin: left;
  opacity: 1;
  animation: ticker 2s linear infinite;
  [data-direction="1"] & {
    animation-direction: reverse;
  }
  span {
    font-weight: 500;
    transform: scaleY(1.8) skewX(20deg);
    [data-direction="1"] & {
      transform: scaleY(1.8) skewX(-20deg);
    }
    display: inline-block;
    margin-right: 1em;
  }
}
