.tabgroup {
  margin: 1rem;
  @include bp(m) {
    margin: $gap;
    height: calc(100% - #{$gap * 2});
  }
}

.tabgroup--content {
  height: 100%;
}

.tabgroup--tabs {
  display: flex;
  button {
    flex-grow: 1;
    text-align: left;
    font-family: $sans;
    font-size: 1.5rem;
    appearance: none;
    padding: 0.3rem;
    padding-left: 0.6rem;
    border: 0;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-right: 1px solid black;
    margin-right: 1rem;
    cursor: pointer;
    color: $purple;
    font-weight: 900;
    border-top-left-radius: $br;
    border-top-right-radius: $br;
    background: $blue;
    position: relative;
    top: 1px;
    &:last-of-type {
      margin-right: 0;
    }
    .corner {
      display: none;
    }
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: "";
      width: calc(100% - 0px);
      height: 10px;
      position: absolute;
      bottom: -8px;
      left: 0;
      display: none;
      background: linear-gradient(to right, $highlightBlue 0, $highlightBlue 2px, $blue 7.5px, $blue calc(100% - 8px), transparent calc(100% - 5px));
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: lefts;
    }
    &:nth-of-type(2) {
      background: $orange;
      border-right: 1px solid black;
      box-shadow: 8px 0 2px -3px $highlightOrange inset, 0px 8px 2px -3px $highlightOrange inset, -8px 0 2px -3px $shadowOrange inset;
      &:after {
        left: auto;
        right: -1px;
        border-right: 1px solid black;
        background: linear-gradient(to left, $shadowOrange 0, $shadowOrange 3px, $orange 6px, $orange calc(100% - 6px), transparent calc(100% - 3px));
      }
    }
    box-shadow: 8px 0 2px -3px $highlightBlue inset, 0px 8px 2px -3px $highlightBlue inset, -8px 0 2px -3px $shadowBlue inset;
    &.active {
      z-index: 2000;
      &:after {
        display: block;
      }
    }
  }
}

.tabgroup--panel {
  display: none;
  border: 1px solid rgba(black, 0.8);
  padding: 1rem;
  background: $blue;
  border-radius: $br;
  position: relative;
  z-index: 1000;
  border-top-left-radius: 0;
  box-shadow: 8px 0 3px -3px $highlight inset, 0px 8px 3px -3px $highlight inset, -8px 0 3px -3px $shadow inset, -0px -8px 3px -3px $shadow inset, 3px 3px 10px $shadow;
  height: auto;
  @include bp(m) {
    height: calc(100% - 3rem);
  }
  &:nth-of-type(2) {
    background: $orange;
  }
  &.active {
    display: block;
  }
}
