* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 100;
}

::selection {
  background: black;
  color: white;
}