$offset: 7px;
$shadow: darken($gray, 60);
$blur: 3px;

.reading--nav {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  button,
  a {
    display: block;
    width: 100%;
    @include bp(m) {
      display: inline-block;
      width: auto;
    }
  }
}

.reading--text {
  background: $green;
  border-radius: $br;
  scrollbar-width: auto;
  scrollbar-color: $purple $gray;

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: $gray;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $purple;
    border-radius: 2px;
  }

  @include bp(m) {
    max-height: calc(100vh - 27rem);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  box-shadow: -3px -3px 5px 0px $shadow, 3px 3px 5px 0px $highlight, 3px 3px 8px 0px rgba(black, 0.5) inset;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: $condensed;
  letter-spacing: -0.035em;
  word-spacing: -0.01em;
  border: 1px solid rgba(black, 0.8);
  h2 {
    font-family: $sans;
    color: $red;
    font-weight: 900;
    margin-bottom: 1rem;
    line-height: 1;
  }
  p {
    hyphens: auto;
    margin-bottom: 1em;
  }
  blockquote p {
  }
}

.reading {
  height: 100%;
}

.screen {
  display: none;
  height: 100%;
  &[data-active="true"] {
    display: block;
  }
}
