.stream {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  iframe {
    flex-basis: 100%;
  }
}

.chat {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  min-height: 50vh;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid black;
  box-shadow: 3px 3px 3px $shadow;
  iframe {
    flex-basis: 100%;
  }
}
