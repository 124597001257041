.btn {
  font-size: var(--ms-5);
  @include bp(m) {
    font-size: var(--ms-1);
  }
  tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  color: lighten($purple, 5);
  font-family: $sans;
  appearance: none;
  background: $gray;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 0;
  padding: 0.06em 0.3em;
  padding-bottom: 0.8rem;
  letter-spacing: -0.01em;
  line-height: 1;
  display: inline-block;
  max-width: 100%;
  text-align: left;
  hyphens: none;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgba(black, 0.9);
  box-shadow: $offset 0 $blur -3px $highlight inset, 0px $offset $blur -3px $highlight inset, $offset * -1 0 $blur -3px $shadow inset, -0px $offset * -1 $blur -3px $shadow inset, 3px 3px 3px -1px rgba(black, 0.3);
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:active {
    background: darken($gray, 5);
    box-shadow: $offset 0 $blur -3px $shadow inset, 0px $offset $blur -3px $shadow inset;
    .button--text {
      transform: translateX(3px) translateY(3px);
    }
  }
}

.button--text {
  display: block;
  font-weight: 900;
}

.calendarLink {
  @extend .btn;
}
